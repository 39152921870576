// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import Favourites from "../../blocks/Favourites/src/Favourites";
import InvertColour2 from "../../blocks/InvertColour2/src/InvertColour2";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import LinkShare from "../../blocks/LinkShare/src/LinkShare";
import Cache2 from "../../blocks/Cache2/src/Cache2";
import InappGifting from "../../blocks/InappGifting/src/InappGifting";
import StoreCredits from "../../blocks/StoreCredits/src/StoreCredits";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import VideoEmbeddingYoutube from "../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube";
import TimeTracker from "../../blocks/TimeTracker/src/TimeTracker";
import Clevertap from "../../blocks/Clevertap/src/Clevertap";
import AdvancedSearch from "../../blocks/AdvancedSearch/src/AdvancedSearch";
import NeuralNetApiForAndroid2 from "../../blocks/NeuralNetApiForAndroid2/src/NeuralNetApiForAndroid2";
import ApplePayIntegration from "../../blocks/ApplePayIntegration/src/ApplePayIntegration";
import Payments from "../../blocks/Payments/src/Payments";
import DataMigration2 from "../../blocks/DataMigration2/src/DataMigration2";
import GooglePayIntegration from "../../blocks/GooglePayIntegration/src/GooglePayIntegration";
import ContextTagging from "../../blocks/ContextTagging/src/ContextTagging";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import CarouselDisplay from "../../blocks/CarouselDisplay/src/CarouselDisplay";
import Settings2 from "../../blocks/Settings2/src/Settings2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import LoadExternalWebsiteInTheApp from "../../blocks/LoadExternalWebsiteInTheApp/src/LoadExternalWebsiteInTheApp";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Themes from "../../blocks/Themes/src/Themes";
import Gamification from "../../blocks/Gamification/src/Gamification";
import BluetoothIntegration19 from "../../blocks/BluetoothIntegration19/src/BluetoothIntegration19";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import AnimationsAndTransition3 from "../../blocks/AnimationsAndTransition3/src/AnimationsAndTransition3";
import Share from "../../blocks/Share/src/Share";
import CharacterCount2 from "../../blocks/CharacterCount2/src/CharacterCount2";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import InappPurchasing from "../../blocks/InappPurchasing/src/InappPurchasing";
import WatsappIntegration3 from "../../blocks/WatsappIntegration3/src/WatsappIntegration3";
import MenuOrdering from "../../blocks/MenuOrdering/src/MenuOrdering";
import AudioLibrary from "../../blocks/AudioLibrary/src/AudioLibrary";
import AudioMusic from "../../blocks/AudioMusic/src/AudioMusic";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import OfflineBrowsing from "../../blocks/OfflineBrowsing/src/OfflineBrowsing";
import AuditTrail from "../../blocks/AuditTrail/src/AuditTrail";
import Reviews from "../../blocks/Reviews/src/Reviews";
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/mobile-input/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/user-additional-detail-input/AdditionalDetailForm";
import FreeTrial from "../../blocks/FreeTrial/src/FreeTrial";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Playlist4 from "../../blocks/Playlist4/src/Playlist4";
import SearchEngineOptimisationseo from "../../blocks/SearchEngineOptimisationseo/src/SearchEngineOptimisationseo";
import ApiIntegration8 from "../../blocks/ApiIntegration8/src/ApiIntegration8";



const routeMap = {
Favourites:{
 component:Favourites,
path:"/Favourites"},
InvertColour2:{
 component:InvertColour2,
path:"/InvertColour2"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
LinkShare:{
 component:LinkShare,
path:"/LinkShare"},
Cache2:{
 component:Cache2,
path:"/Cache2"},
InappGifting:{
 component:InappGifting,
path:"/InappGifting"},
StoreCredits:{
 component:StoreCredits,
path:"/StoreCredits"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
ContentManagement:{
 component:ContentManagement,
path:"/ContentManagement"},
VideoEmbeddingYoutube:{
 component:VideoEmbeddingYoutube,
path:"/VideoEmbeddingYoutube"},
TimeTracker:{
 component:TimeTracker,
path:"/TimeTracker"},
Clevertap:{
 component:Clevertap,
path:"/Clevertap"},
AdvancedSearch:{
 component:AdvancedSearch,
path:"/AdvancedSearch"},
NeuralNetApiForAndroid2:{
 component:NeuralNetApiForAndroid2,
path:"/NeuralNetApiForAndroid2"},
ApplePayIntegration:{
 component:ApplePayIntegration,
path:"/ApplePayIntegration"},
Payments:{
 component:Payments,
path:"/Payments"},
DataMigration2:{
 component:DataMigration2,
path:"/DataMigration2"},
GooglePayIntegration:{
 component:GooglePayIntegration,
path:"/GooglePayIntegration"},
ContextTagging:{
 component:ContextTagging,
path:"/ContextTagging"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
LoadExternalWebsiteInTheApp:{
 component:LoadExternalWebsiteInTheApp,
path:"/LoadExternalWebsiteInTheApp"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Themes:{
 component:Themes,
path:"/Themes"},
Gamification:{
 component:Gamification,
path:"/Gamification"},
BluetoothIntegration19:{
 component:BluetoothIntegration19,
path:"/BluetoothIntegration19"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
AnimationsAndTransition3:{
 component:AnimationsAndTransition3,
path:"/AnimationsAndTransition3"},
Share:{
 component:Share,
path:"/Share"},
CharacterCount2:{
 component:CharacterCount2,
path:"/CharacterCount2"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
InappPurchasing:{
 component:InappPurchasing,
path:"/InappPurchasing"},
WatsappIntegration3:{
 component:WatsappIntegration3,
path:"/WatsappIntegration3"},
MenuOrdering:{
 component:MenuOrdering,
path:"/MenuOrdering"},
AudioLibrary:{
 component:AudioLibrary,
path:"/AudioLibrary"},
AudioMusic:{
 component:AudioMusic,
path:"/AudioMusic"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
OfflineBrowsing:{
 component:OfflineBrowsing,
path:"/OfflineBrowsing"},
AuditTrail:{
 component:AuditTrail,
path:"/AuditTrail"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AutomaticCheckoutCalculation2:{
 component:AutomaticCheckoutCalculation2,
path:"/AutomaticCheckoutCalculation2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
FreeTrial:{
 component:FreeTrial,
path:"/FreeTrial"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Playlist4:{
 component:Playlist4,
path:"/Playlist4"},
SearchEngineOptimisationseo:{
 component:SearchEngineOptimisationseo,
path:"/SearchEngineOptimisationseo"},
ApiIntegration8:{
 component:ApiIntegration8,
path:"/ApiIntegration8"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;