import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";

// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import OnboardingAdapter from "../../blocks/adapters/src/OnboardingAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";

//Assembler generated adapters start
const onboardingAdapter = new OnboardingAdapter();
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S { }

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this;
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <SafeAreaView>
        <ScrollView contentContainerStyle={styles.scrollView} bounces={false}>
          <View style={styles.container}>
            <View style={styles.header}>
              <Text style={styles.welcome}>
                Welcome to DasubhashitamAppFinal!
              </Text>
            </View>

            <Text style={styles.instructions}>{instructions}</Text>
            <Text style={styles.header}>DEFAULT BLOCKS</Text>
            <CustomTextItem
              content={'InfoPage'}
              onPress={() => navigation.navigate("InfoPage")}
            />
            <CustomTextItem
              content={'Alert'}
              onPress={() => this.showAlert("Example", "This happened")}
            />
<CustomTextItem content={'Customisableusersubscriptions'}  onPress={() => navigation.navigate("Customisableusersubscriptions")} />
<CustomTextItem content={'Onboardingguide'}  onPress={() => navigation.navigate("Onboardingguide")} />
<CustomTextItem content={'core'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Splashscreen'}  onPress={() => navigation.navigate("Splashscreen")} />
<CustomTextItem content={'Catalogue'}  onPress={() => navigation.navigate("Catalogue")} />
<CustomTextItem content={'Categoriessubcategories'}  onPress={() => navigation.navigate("Categoriessubcategories")} />
<CustomTextItem content={'PhoneNumberInput'}  onPress={() => navigation.navigate("PhoneNumberInput")} />
<CustomTextItem content={'CountryCodeSelector'}  onPress={() => navigation.navigate("CountryCodeSelector")} />
<CustomTextItem content={'OTPInputAuth'}  onPress={() => navigation.navigate("OTPInputAuth")} />
<CustomTextItem content={'EmailAccountRegistration'}  onPress={() => navigation.navigate("EmailAccountRegistration")} />
<CustomTextItem content={'SocialMediaAccountRegistrationScreen'}  onPress={() => navigation.navigate("SocialMediaAccountRegistrationScreen")} />
<CustomTextItem content={'social-media-account'}  onPress={() => this.showAlert("Error", "Could not determine assembler export")} />
<CustomTextItem content={'Favourites'}  onPress={() => navigation.navigate("Favourites")} />
<CustomTextItem content={'FreeTrial'}  onPress={() => navigation.navigate("FreeTrial")} />
<CustomTextItem content={'AnimationsAndTransition3'}  onPress={() => navigation.navigate("AnimationsAndTransition3")} />
<CustomTextItem content={'Gamification'}  onPress={() => navigation.navigate("Gamification")} />
<CustomTextItem content={'Share'}  onPress={() => navigation.navigate("Share")} />
<CustomTextItem content={'Settings2'}  onPress={() => navigation.navigate("Settings2")} />
<CustomTextItem content={'MenuOrdering'}  onPress={() => navigation.navigate("MenuOrdering")} />
<CustomTextItem content={'AudioMusic'}  onPress={() => navigation.navigate("AudioMusic")} />
<CustomTextItem content={'AudioLibrary'}  onPress={() => navigation.navigate("AudioLibrary")} />
<CustomTextItem content={'AuditTrail'}  onPress={() => navigation.navigate("AuditTrail")} />
<CustomTextItem content={'InvertColour2'}  onPress={() => navigation.navigate("InvertColour2")} />
<CustomTextItem content={'ApiIntegration8'}  onPress={() => navigation.navigate("ApiIntegration8")} />
<CustomTextItem content={'Themes'}  onPress={() => navigation.navigate("Themes")} />
<CustomTextItem content={'AutomaticCheckoutCalculation2'}  onPress={() => navigation.navigate("AutomaticCheckoutCalculation2")} />
<CustomTextItem content={'CharacterCount2'}  onPress={() => navigation.navigate("CharacterCount2")} />
<CustomTextItem content={'WatsappIntegration3'}  onPress={() => navigation.navigate("WatsappIntegration3")} />
<CustomTextItem content={'Clevertap'}  onPress={() => navigation.navigate("Clevertap")} />
<CustomTextItem content={'ContextTagging'}  onPress={() => navigation.navigate("ContextTagging")} />
<CustomTextItem content={'EmailNotifications'}  onPress={() => navigation.navigate("EmailNotifications")} />
<CustomTextItem content={'Playlist4'}  onPress={() => navigation.navigate("Playlist4")} />
<CustomTextItem content={'Reviews'}  onPress={() => navigation.navigate("Reviews")} />
<CustomTextItem content={'InappGifting'}  onPress={() => navigation.navigate("InappGifting")} />
<CustomTextItem content={'TimeTracker'}  onPress={() => navigation.navigate("TimeTracker")} />
<CustomTextItem content={'AdminConsole3'}  onPress={() => navigation.navigate("AdminConsole3")} />
<CustomTextItem content={'BluetoothIntegration19'}  onPress={() => navigation.navigate("BluetoothIntegration19")} />
<CustomTextItem content={'VideoEmbeddingYoutube'}  onPress={() => navigation.navigate("VideoEmbeddingYoutube")} />
<CustomTextItem content={'AdvancedSearch'}  onPress={() => navigation.navigate("AdvancedSearch")} />
<CustomTextItem content={'Cache2'}  onPress={() => navigation.navigate("Cache2")} />
<CustomTextItem content={'SearchEngineOptimisationseo'}  onPress={() => navigation.navigate("SearchEngineOptimisationseo")} />
<CustomTextItem content={'LoadExternalWebsiteInTheApp'}  onPress={() => navigation.navigate("LoadExternalWebsiteInTheApp")} />
<CustomTextItem content={'OfflineBrowsing'}  onPress={() => navigation.navigate("OfflineBrowsing")} />
<CustomTextItem content={'PaymentAdmin2'}  onPress={() => navigation.navigate("PaymentAdmin2")} />
<CustomTextItem content={'Payments'}  onPress={() => navigation.navigate("Payments")} />
<CustomTextItem content={'CarouselDisplay'}  onPress={() => navigation.navigate("CarouselDisplay")} />
<CustomTextItem content={'LinkShare'}  onPress={() => navigation.navigate("LinkShare")} />
<CustomTextItem content={'ContentManagement'}  onPress={() => navigation.navigate("ContentManagement")} />
<CustomTextItem content={'DataMigration2'}  onPress={() => navigation.navigate("DataMigration2")} />
<CustomTextItem content={'NeuralNetApiForAndroid2'}  onPress={() => navigation.navigate("NeuralNetApiForAndroid2")} />
<CustomTextItem content={'StoreCredits'}  onPress={() => navigation.navigate("StoreCredits")} />
<CustomTextItem content={'ApplePayIntegration'}  onPress={() => navigation.navigate("ApplePayIntegration")} />
<CustomTextItem content={'InappPurchasing'}  onPress={() => navigation.navigate("InappPurchasing")} />
<CustomTextItem content={'GooglePayIntegration'}  onPress={() => navigation.navigate("GooglePayIntegration")} />

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;